'use client'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useState, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from '@/components/theme-provider'
import Script from 'next/script'
import type * as React from 'react'

const env = process.env.NODE_ENV

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  })
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

export const queryClient = new QueryClient()

export function QueryProvider({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export function Providers({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<string>('system')

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) {
      setTheme(savedTheme)
    }
  }, [])

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme={theme}
      enableSystem={true}
      disableTransitionOnChange
    >
      <Script
        src="https://app.chatwoot.com/packs/js/sdk.js"
        onLoad={() => {
          ;(window as any).chatwootSDK.run({
            websiteToken: '8UcgWBQqnN3kwPonnFPPapeA',
            baseUrl: 'https://app.chatwoot.com',
          })
        }}
      />
      <PHProvider>
        <QueryProvider>
          {children}
          {env === 'development' ? <ReactQueryDevtools /> : <></>}
        </QueryProvider>
      </PHProvider>
    </ThemeProvider>
  )
}
